<div class="modal" tabindex="-1">
  <div class="modal-dialog" [ngClass]="customDialogClass">
    <div class="modal-content" [ngClass]="class">
      @if (showCloseIcon) {
        <div class="close">
          <img
            src="/assets/images/close.svg"
            alt="close"
            (click)="onBtnClicked()"
          />
        </div>
      }
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
      </div>
      <div class="modal-body">
        <p>{{ body }}</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="return-btn cancel-btn"
          (click)="onBtnClicked()"
        >
          {{ buttonTxt }}
        </button>
        @if (secondButtonTxt) {
          <button type="button" class="return-btn" (click)="onBtnClick()">
            {{ secondButtonTxt }}
          </button>
        }
      </div>
    </div>
  </div>
</div>
